<template>
	<b-card>
		<div class="row justify-content-end px-3 mt-2">
			<b-col md="3">
				<b-form-group>
					<template v-slot:label>
						Select Company<span class="text-danger">*</span>
					</template>
					<v-select
						v-model="selectedCompany"
						label="company_name"
						:options="companyList"
						:reduce="(e) => e.id"
						@input="getModules()"
					/>
				</b-form-group>
			</b-col>
		</div>
		<div v-if="moduleLists && moduleLists.length > 0">
			<div v-for="modules in moduleLists" :key="modules.id">
				<div class="device-item">
					<div class="d-flex align-items-center justify-content-between">
						<div class="">
							<div>{{ modules.module_name }}</div>
						</div>
						<!-- <b-form-checkbox
							v-model="company.module_permission"
							value="Active"
							unchecked-value="InActive"
							name="status"
							switch
							inline
							@change="assignModule()"
						>
						</b-form-checkbox> -->
						<label class="switch">
							<input
								type="checkbox"
								:value="modules.id"
								:checked="checkModule(modules.id)"
								@change="addModules(modules.id)"
							/>
							<span class="slider round"></span>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img
				:src="
					require('@/assets/images/svg/infographics/no_device_infographic.svg')
				"
				class="mr-2"
			/>
			<h4 class="mt-2 Secondary">No Modules Found</h4>
		</div>
		<b-modal
			id="add-device"
			title="Add Device"
			ok-only
			ok-title="Add"
			@ok="addDevice"
			ref="my-modal"
		>
			<b-form method="post">
				<b-form-group label="Enter Device Id">
					<div class="form-label-group">
						<b-form-input
							name="deviceId"
							v-model="deviceId"
							placeholder="Enter Device Id"
							type="text"
						/>
					</div>
				</b-form-group>
			</b-form>
		</b-modal>

		<!-- confirmation modal -->
		<b-modal
			id="modal-confirmation"
			title="Add Device"
			ok-only
			class="primary badge-light-success p-1"
			ok-title="Add"
			@ok="() => attachDevice()"
		>
			<b-card-text>
				Are you sure, you want to attach this device ?
			</b-card-text>
		</b-modal>
	</b-card>
</template>

<script>
import {
	BCard,
	BRow,
	BImg,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BFormCheckbox,
} from "bootstrap-vue";

import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		BCard,
		BRow,
		BImg,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BTooltip,
		BFormDatepicker,
		BFormCheckbox,
	},
	data() {
		return {
			deviceList: [],
			deviceId: null,
			companyId: null,
			moduleLists: [],
			companyList: [],
			selectedCompany: null,
			device_status: null,
			assigned_modules: [],
			company: {
				module_permission: null,
			},
		};
	},
	created: function () {
		this.getCompanyList();
		// this.getModules();
	},
	methods: {
		moment() {
			return moment();
		},
		getModules() {
			let companyId = this.selectedCompany;
			axiosIns
				.get(`web/all-modules?companyId=${companyId}`)
				.then((response) => {
					this.moduleLists = response.data.allModules;
					this.assigned_modules = response.data.assigned_modules;
				})
				.catch((error) => {
					error;
				});
		},
		addModules(moduleId) {
			let companyId = this.selectedCompany;
			var data = new FormData();
			data.append("moduleId", moduleId);
			data.append("companyId", companyId);
			axiosIns
				.post(`web/assign-company-module`, data)
				.then((res) => {
					const data = res.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: data,
						},
					});
					this.getDeviceList();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
				});
		},

		checkModule(module_id) {
			if (module_id != null) {
				let assignedModules = this.assigned_modules;
				let module = assignedModules.find((element) => {
					return element.module_id == module_id;
				});
				if (module) {
					return true;
				} else {
					return false;
				}
			}
		},
		getCompanyList() {
			axiosIns
				.get(`web/getAllCompanyList`)
				.then((response) => {
					this.companyList = response.data.company_list;
				})
				.catch((error) => {
					error;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.device-item {
	border: 1px solid #ebe9f1;
	border-radius: 0.357rem;
	padding: 1rem 1.5rem 1rem 1.3rem;
	margin: 1rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 25px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 20px;
	left: 2px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #2196f3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>
